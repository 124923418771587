<template>
  <section id="dashboard-prediccion">

    <b-row>
      <b-col>
        <BrunaForm ref="searchRef" :fields="this.search.fields" :card="this.search.card" @change="searchChange2">
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-form-textarea id="textarea" placeholder="Enter something..." rows="10" max-rows="6" @change="debugChange"
            v-if="debug"></b-form-textarea>

          <ecualizador ref="ecualizadorRef" :metrics="input_variables" @search-change="searchChange"
            @rowDataChanged="rowDataChanged">
            <template v-slot:footer-ecualizador>

              <b-form inline>

                <b-form-checkbox id="checkbox-incluir-variables" v-model="incluir_variables" name="checkbox-incluir-variables"  class="mb-2 mr-sm-2 mb-sm-0">
                    Incluir variables de modelamiento
                  </b-form-checkbox>
                


                <b-button size="sm" @click="simulate" variant="primary">Simular</b-button>

              </b-form>


            </template>
          </ecualizador>

          <template #overlay>
            <div class="text-center">
              <b-spinner label="Loading..." />
              <p id="cancel-label">{{ overlayMessage }}</p>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>


    <b-row>
      <b-col sm="12">
        <ag-grid-table ref="grid-historico-runs" :debug="true" :configUrl="historicoOptimizationsConfig"
          :dataUrl="historicoOptimizationsData" @gridReady="onGridReady" @getRowsLoaded="onGetRowsLoaded"
          rowSelection="multiple" :rowClassRules="rowClassRules" :pinnedTopRowData="pinnedTopRowData"
          @selectionChanged="onSelectionChanged" @actionReceived="actionReceived" :getRowNodeId="getRowNodeId"
          :helper="true">
          <template v-slot:actions>
            <!--
            <div>
              <b-button-toolbar
                aria-label="Toolbar with button groups and input groups"
                justify
              >
                <b-button-group style="margin-bottom: 1rem">
                  <b-button
                    size="sm"
                    :disabled="disableCompareRuns"
                    @click="compareRuns()"
                    variant="primary"
                    >{{ $t("Comparar") }}</b-button
                  >
                </b-button-group>
              </b-button-toolbar>
            </div>
            -->
          </template>
        </ag-grid-table>
      </b-col>
    </b-row>
  </section>
</template>
    
<script>

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import VueSlider from 'vue-slider-component'
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";


import useApiServices from '@/services/useApiServices.js';

import HistoricoPlanificacionesActions from "./HistoricoPlanificacionesActions.vue";

import Ecualizador from "./Planificador/Ecualizador2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


import axios from "@axios";
var qs = require('qs');

import
{
  BRow,
  BCol,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,

  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,

  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,

  BOverlay,
  BSpinner


} from "bootstrap-vue";



export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,

    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,

    VueSlider,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,

    Ecualizador,

    BOverlay,
    BSpinner,

    BrunaForm,
    BFormCheckbox,

    ToastificationContent,



    historicoPlanificacionesActions: HistoricoPlanificacionesActions


  },

  methods: {

    rowDataChanged(rowData)
    {
      console.log("rowDataChanged", { rowData })

      this.rowData = rowData
    },

    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },



    searchChange2(field)
    {
      console.log("searchChange", field);

      if (field.id == "mdf")
      {

        let fnd_field = this.findFieldByName(this.search.fields, 'fnd')
        let eqp_field = this.findFieldByName(this.search.fields, 'eqp')
        let trn_field = this.findFieldByName(this.search.fields, 'trn')
        let safra_field = this.findFieldByName(this.search.fields, 'safra')

        fnd_field.options = [{ value: null, text: "Selecciona una opción" }]
        fnd_field.value = null

        eqp_field.options = [{ value: null, text: "Selecciona una opción" }]
        eqp_field.value = null

        trn_field.options = [{ value: null, text: "Selecciona una opción" }]
        trn_field.value = null

        safra_field.options = [{ value: null, text: "Selecciona una opción" }]
        safra_field.value = null



        let keys = Object.keys(this.searchResults[field.value]);
        keys.sort();

        let options = [
          { value: null, text: "Selecciona una opción" }
        ]

        keys.forEach(item =>
        {
          options.push({ value: item, text: item })
        })

        fnd_field.options = options;


      }

      if (field.id == "fnd")
      {

        let mdf_field = this.findFieldByName(this.search.fields, 'mdf')
        let eqp_field = this.findFieldByName(this.search.fields, 'eqp')
        let trn_field = this.findFieldByName(this.search.fields, 'trn')
        let safra_field = this.findFieldByName(this.search.fields, 'safra')

        eqp_field.options = [{ value: null, text: "Selecciona una opción" }]
        eqp_field.value = null

        trn_field.options = [{ value: null, text: "Selecciona una opción" }]
        trn_field.value = null

        safra_field.options = [{ value: null, text: "Selecciona una opción" }]
        safra_field.value = null


        let keys = Object.keys(this.searchResults[mdf_field.value][field.value]);
        keys.sort();

        let options = [
          { value: null, text: "Selecciona una opción" }
        ]

        keys.forEach(item =>
        {
          options.push({ value: item, text: item })
        })

        eqp_field.options = options;


      }


      if (field.id == "eqp")
      {

        let mdf_field = this.findFieldByName(this.search.fields, 'mdf')
        let fnd_field = this.findFieldByName(this.search.fields, 'fnd')
        let trn_field = this.findFieldByName(this.search.fields, 'trn')
        let safra_field = this.findFieldByName(this.search.fields, 'safra')

        trn_field.options = [{ value: null, text: "Selecciona una opción" }]
        trn_field.value = null

        safra_field.options = [{ value: null, text: "Selecciona una opción" }]
        safra_field.value = null



        let keys = Object.keys(this.searchResults[mdf_field.value][fnd_field.value][field.value]);
        keys.sort();

        let options = [
          { value: null, text: "Selecciona una opción" }
        ]

        keys.forEach(item =>
        {
          options.push({ value: item, text: item })
        })

        trn_field.options = options;


      }

      if (field.id == "trn")
      {
        let mdf_field = this.findFieldByName(this.search.fields, 'mdf')
        let fnd_field = this.findFieldByName(this.search.fields, 'fnd')
        let eqp_field = this.findFieldByName(this.search.fields, 'eqp')
        let safra_field = this.findFieldByName(this.search.fields, 'safra')

        safra_field.options = [{ value: null, text: "Selecciona una opción" }]
        safra_field.value = null




        let keys = Object.keys(this.searchResults[mdf_field.value][fnd_field.value][eqp_field.value][field.value]);
        keys.sort();

        let options = [
          { value: null, text: "Selecciona una opción" }
        ]

        keys.forEach(item =>
        {
          options.push({ value: item, text: item })
        })

        safra_field.options = options;


      }

      if (field.id == "safra")
      {
        let mdf_field = this.findFieldByName(this.search.fields, 'mdf')
        let fnd_field = this.findFieldByName(this.search.fields, 'fnd')
        let eqp_field = this.findFieldByName(this.search.fields, 'eqp')
        let trn_field = this.findFieldByName(this.search.fields, 'trn')
        let safra_field = this.findFieldByName(this.search.fields, 'safra')


        let name = this.searchResults[mdf_field.value][fnd_field.value][eqp_field.value][trn_field.value][safra_field.value]


        this.fet_name = name

        console.log("fet_name: ", this.fet_name)

      }



    },


    debugChange(value)
    {

      console.log(value)

      let decoded = JSON.parse(value)





    },


    roundValue(value, decimales = 2)
    {
      let tmp = Math.pow(10, decimales)

      return Math.round(value * tmp) / tmp
    },


    actionReceived(action_name, data, params)
    {
      console.log("actionReceived", { action_name, data, params })
    },

    searchChange(value)
    {
      console.log(value)

      this.input_variables.forEach(metric =>
      {

        if (metric.name.match(new RegExp(value, 'i')))
        {
          metric.show = true
        } else
        {
          metric.show = false

        }



      })

    },


    compareRuns()
    {

      this.$router.push({ name: 'compare-simulations', query: { ids: this.selectedRows.map(run => run.id) } })
    },




    onGridReady(gridApi, gridColumnApi, params)
    {

      this.gridApi = gridApi

      console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })



    },

    onSelectionChanged(selectedRows)
    {

      this.selectedRows = selectedRows

      this.disableCompareRuns = (selectedRows.length < 2)
      this.disableRellenar = !(selectedRows.length == 1)

    },

    onGetRowsLoaded(gridApi, gridColumnApi, params)
    {
      console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })

      this.tableLoaded = true;


    },

    simulate()
    { 


      let ajustes = {
        fet_name: this.fet_name,
        incluir_variables: this.incluir_variables
      };

      let rowData = this.$refs.ecualizadorRef.getRowData()


      //console.log(data)      

      this.$bvModal.msgBoxConfirm('Se creara una nueva simulación', {
        title: 'Confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Continuar',
        cancelTitle: 'Cancelar',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value =>
        {

          if (value)
          {


            let data = {
              service_name: this.service_name,
              ajustes: ajustes,
              data: this.input_variables.reduce((acumulador, variable) => { acumulador[variable.name] = rowData[variable.name].values; return acumulador }, {})
            };

            console.log(data)

            this.overlayMessage = "Cargando predicción..."
            this.showOverlay = true

            axios.post(useApiServices.modelsCreateInvocationUrl, data)
              .then((response) =>
              {

                console.log(response)

                this.showOverlay = false
                this.$refs['grid-historico-runs'].refreshRows()

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t('Información'),
                    icon: "UploadIcon",
                    variant: "success",
                    html: this.$t('La nueva simulación se ha creado con éxito.'),
                  },
                });


              })
              .catch((error) =>
              {
                this.showOverlay = false

                let message = error.response.data.message;

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t('Ocurrió un Error'),
                    icon: "UploadIcon",
                    variant: "danger",
                    html: message,
                  },
                });



              });

          }

        })
        .catch(err =>
        {
          // An error occurred
        })









    },

    accionDescargar(data)
    {
      console.log(data)

      if (data.data.output_data == null)
      {
        return
      }

      let optimization_id = data.data.id;

      let query = qs.stringify({ optimization_id: optimization_id })

      useApiServices.getDownloadFile(useApiServices.detalleOptimizacion.detalleOptimizacionDownloadExcel + "?" + query)


    },

    accionVerDetalles(data)
    {

      console.log(data)

      if (data.data.output_data == null)
      {
        return
      }

      let optimization_id = data.data.id;



      this.$router.push({ name: 'optimization-details', query: { optimization_id: optimization_id } })

    },


    loadVariables()
    {
      this.overlayMessage = "Cargando variables..."
      this.showOverlay = true

      return useApiServices.modelsRead({ name: this.model_name })
        .then((response) =>
        {

          let input_variables = response.data.input_variables.map(variable =>
          {

            let min = Math.floor(variable.meta_data.minimo)
            let max = Math.ceil(variable.meta_data.maximo)

            let value = []
            let media = min + (max - min) / 2

            for (let i = 0; i < 30; i++)
            {
              value.push(media)
            }

            // console.log('VARIABLE => ',variable)
            //let value = min + (max - min) / 2

            if (variable.meta_data.options)
            {
              return {
                name: variable.name,
                value: variable.meta_data.options[0],
                grupo: variable.meta_data.grupo,
                data: variable.meta_data.options,
                show: false,
                disabledInput: true

              }
            }
            else
            {
              return {
                name: variable.name,
                value: value,
                grupo: variable.meta_data.grupo,
                min: min,
                max: max,
                marks: [min, media, max],
                interval: 0.01,
                show: false,
                disabledInput: false

              }

            }



          });

          this.input_variables = input_variables

          this.showOverlay = false

        })
        .catch((error) =>
        {

        });

    }


  },


  beforeUnmount()
  {
    this.$pusher.unsubscribe('optimizations');
  },

  beforeRouteLeave(to, from, next)
  {

    this.$pusher.unsubscribe('optimizations');

    next()
  },


  mounted()
  {

    let self = this;

    //Se escucha a la llegada de las notificaciones de pusher

    var channel = this.$pusher.subscribe('optimizations');

    channel.bind('OptimizationUpdateEvent', function (data)
    {

      let userData = JSON.parse(localStorage.getItem("userData"));

      if (userData && userData.client.id == data.client_id)
      {
        let invocation_id = data.invocation_id

        useApiServices.getInvocation(invocation_id)
          .then((response) =>
          {
            let invocation = response.data

            console.log(invocation)

            if (self.tableLoaded && self.$refs['grid-historico-runs'])
            {
              self.$refs['grid-historico-runs'].updateRow(invocation_id, invocation)
            }



          })
          .catch(function (error)
          {
            console.log(error)
          })




      }


    });


    axios.request({
      method: "get",
      url: useApiServices.historicoData.search,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) =>
      {
        this.searchResults = response.data;


        let options = [
          { value: null, text: "Selecciona una opción" }
        ]

        let keys = Object.keys(this.searchResults);
        keys.forEach(item =>
        {
          options.push({ value: item, text: item })
        })

        let mdf_field = this.findFieldByName(this.search.fields, 'mdf')

        mdf_field.options = options;
        mdf_field.value = 'ML';
      })






  },




  created()
  {

    this.loadVariables()
      .then(() =>
      {


      })



  },


  data()
  {

    let debug = (this.$route.query.debug == '1')



    return {

      model_name: "CANIAS-PREDICCION-RENDIMIENTO-00",
      service_name: "CANIAS-PREDICCION-RENDIMIENTO-00",

      getRowNodeId: (params) =>
      {
        return params.id;
      },

      actions: [
        { name: "Ver Detalles", icon: "DownloadCloudIcon", action: this.accionVerDetalles },
        { name: "Descargar", icon: "DownloadCloudIcon", action: this.accionDescargar },
      ],

      debug: debug,

      showOverlay: false,
      overlayMessage: "Cargando...",
      tableLoaded: false,



      rowClassRules: {

        'escenario-base': (params) =>
        {
          if (params.data == undefined)
          {
            return false;
          }

          return params.data.default === 1;
        }

      },

      gridApi: null,

      itemsData: [],
      pinnedTopRowData: [],

      historicoOptimizationsConfig: useApiServices.historicoOptimizationsConfig,
      historicoOptimizationsData: useApiServices.historicoOptimizationsData,

      disableCompareRuns: true,
      disableRellenar: true,
      selectedRows: [],

      incluir_variables: 'accepted',

      rowData: [],

      input_variables: [],

      fet_name: null,

      search: {
        card: {
          title: "Búsqueda",
          subTitle: "",
          tooltip: "",
          sidebarContent: {
            title: "Búsqueda",
            body: '<p>Esta herramienta está diseñada para simular de forma manual las condiciones de un escenario particular, ya sea de variables climáticas o variables de campo, y usar el modelo de predicción idóneo para que prediga el rendimiento (Ton/Ha) de ese turno bajo las condiciones definidas y considerando los días desde el cultivo.</p>'
          }
        },
        fields: [


          {
            label: "MDF",
            type: "select",
            id: 'mdf',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },

          {
            label: "FND",
            type: "select",
            id: 'fnd',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },

          {
            label: "EQP",
            type: "select",
            id: 'eqp',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },

          {
            label: "TRN",
            type: "select",
            id: 'trn',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },


          {
            label: "Safra",
            type: "select",
            id: 'safra',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
          },

        ]
      },





    };
  },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
  
  
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

.escenario-base {
  background-color: #00bcd4 !important;
}
</style>
  
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
    