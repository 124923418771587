<template>
  <b-card no-body>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title>{{ $t(cardTitle) }}</b-card-title>
        <b-card-sub-title>{{
          $t("Selecciona los valores de cada variable para simular escenarios")
        }}</b-card-sub-title>
      </div>

      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <feather-icon
              icon="HelpCircleIcon"
              v-b-toggle.sidebar-1
              size="20"
            />
          </li>
        </ul>
      </div>
    </div>
    <div>
      <b-sidebar
        id="sidebar-1"
        title="Variables de modelamiento"
        shadow
        bg-variant="dark"
        text-variant="light"
        width="450px"
        right
        backdrop
      >
        <div class="px-2 py-2">
          <p>Te explicamos qué significa algunas variable claves:</p>
          <ul>
            <li>
              <strong>Rendimiento: </strong>medido en toneladas o toneladas por
              hectárea. Además contiene las fechas de siembra (fsiembra) y de
              cosecha (fcosecha) de cada turno-safra.
            </li>
            <br />
            <li>
              <strong>Fertilización: </strong>está registrada a nivel de
              parcela. Por consecuencia, se calculó de igual manera el
              fertilizante ponderado basándonos en el área de la parcela.
            </li>
            <br />
            <li>
              <strong>Variables de Clima: </strong>extraída de las estaciones
              Montelima 01-R1 y Montelima 02-R5.
            </li>
            <br />
            <li>
              <strong>Curva Gompertz: </strong>La curva Gompertz es una
              parametrización de una curva sigmoidal que ha sido usada para
              modelar el crecimiento de animales, plantas, bacterias y células
              cancerígenas. Esta aproximación del crecimiento nos permite
              rellenar de manera más sensata las biométricas ausentes en la
              data.
            </li>
          </ul>
          <p>Ten en cuenta que:</p>
          <ul>
            <li>
              Puedes dejar las variables con sus valores pre definidos y
              simplemente simular los escenarios de esa forma.
            </li>
            <br />
            <li>
              Puedes modificar todas o solo algunas de las variables dentro de
              los rangos históricos de cada una.
            </li>
          </ul>
          <p>
            Ten en cuenta que si solo modificas un par de variables para la
            predicción, se utilizarán los valores pre definidos de las variables
            no modificadas.
          </p>
        </div>
      </b-sidebar>

      <!-- Card Actions -->
      <!--
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li></li>
            <li>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Search"
                  debounce="400"
                  @input="searchChange"
                />
              </b-input-group>
            </li>
          </ul>
        </div>
      -->
    </div>

    <!-- Card Body  -->
    <b-card-body style="width: 100%">

        <!--
      <b-row>
        <b-col lg="3" xl="3" v-for="metric in metrics" :key="metric.name">
          <b-form-checkbox
            v-model="metric.show"
            :value="true"
            :unchecked-value="false"
            switch
          >
            {{ metric.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>
-->
      <b-row> 
        <b-col>
          <ag-grid-vue
            style="width: 100%; height: 500px"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :rowData="rowData"
            rowSelection="single"
            @selection-changed="onSelectionChanged"
            @grid-ready="onGridReady"
            :getRowNodeId="getRowNodeId"
            @cellValueChanged="onCellValueChanged"
          ></ag-grid-vue>
          <div>
            <b-button-toolbar aria-label="" justify>
              <b-button-group style="margin-top: 1rem; margin-bottom: 1rem">
                <b-button
                  size="sm"
                  :disabled="this.selectedRows.length <= 0"
                  @click="fillSelected()"
                  variant="secondary"
                  >{{ $t("Rellenar Seleccionado") }}</b-button
                >
              </b-button-group>
            </b-button-toolbar>
          </div>

          <b-modal v-if="selectedRows.length > 0" id="bv-modal-example" :title="selectedRows[0].metric.name" button-size="sm" @ok="modalOk">
            <div style="padding: 25px 25px" >
              <b-row>
                <b-col>
                  <vue-slider
                    style=""
                    direction="ltr"
                    v-model="selectedRowValue"
                    :tooltip="'active'"
                    :min="selectedRows[0].metric.min"
                    :marks="selectedRows[0].metric.marks"
                    :max="selectedRows[0].metric.max"
                    :data="selectedRows[0].metric.data || undefined"
                    :interval="selectedRows[0].metric.interval"
                    :width="250"
                    class="mb-2 vue-slider-secondary"
                  />
                </b-col>
                <b-col>
                  <b-form-input
                    v-model="selectedRowValue"
                    placeholder=""
                  ></b-form-input>
                </b-col>
              </b-row>
            </div>
          </b-modal>
        </b-col>
      </b-row>
  

    </b-card-body>

    <template #footer>
      <slot name="footer-ecualizador">
        <b-button size="sm" @click="simulate" variant="success"
          >Simular</b-button
        >
      </slot>
    </template>
  </b-card>
</template>
    
    <script>
import
{
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BFormInput,
  BFormCheckbox
} from "bootstrap-vue";

const axios = require("axios");

import VueSlider from 'vue-slider-component'
import { AgGridVue } from "ag-grid-vue";

import CustomSliderEditor from './CustomSliderEditor.vue';


export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VueSlider,
    BFormInput,
    BFormCheckbox,
    AgGridVue,
    CustomSliderEditor

  },

  directives: {
  },

  watch: {
    metrics:
    {
      handler(newVal, oldVal)
      {
        console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        this.rowData = this.getRowsData()

      },
      deep: true
    }
  },

  data: function ()
  {

    let columnDefs2 = [
      { headerName: 'Variable', field: 'variable', pinned: 'left', checkboxSelection: true },
      { headerName: 'Grupo', field: 'grupo' }
    ]

    for (let dia = 0; dia < 30; dia++)
    {
      columnDefs2.push({
        headerName: 'Dia ' + dia,
        field: 'values.' + dia,
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'CustomSliderEditor',
        cellEditorParams: {
          // make "country" value available to cell editor
          country: 'Ireland'
        },
      })

    }

    // console.log({ columnDefs2 })
      // console.log('METRICS => ', this.metrics);




    return {
      columnDefs: columnDefs2,
      rowData: this.getRowsData(),

      gridApi: null,
      columnApi: null,

      selectedRowValue: null,

      selectedRows: [],
      selectedNodes: [],
      getRowNodeId: null,

      selected: [],
      options: this.metrics.map((metric) => { return { text: metric.name, value: metric } })

    };
  },
  created()
  {

    this.getRowNodeId = (data) => {
      return data.variable;
    };

  },

  mounted()
  {

  },

  methods: {

    getRowData()
    {
        let out = {}

        this.rowData.forEach(item => {
            out[item.variable] = item
        })

        return out
    },

    onCellValueChanged(params)
    {

        this.$emit('rowDataChanged', this.rowData);
    },

    modalOk()
    {
        let selectedRow = this.selectedRows[0]
        let selectedNode = this.selectedNodes[0]

        for (let dia = 0; dia < 30; dia++)
        {
            selectedRow.values['' + dia] = this.selectedRowValue
        }

        selectedNode.setData(selectedRow)

        this.$emit('rowDataChanged', this.rowData);


    },

    fillSelected()
    {
      this.$bvModal.show('bv-modal-example')
    },

    onSelectionChanged()
    {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.selectedNodes = this.gridApi.getSelectedNodes();
    },
    onGridReady(params)
    {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

    },

    getRowsData()
    {
      let rowsData = []

      // console.log('METRICS => ', this.metrics);

      this.metrics.forEach((metric) =>
      {

    // console.log(metric)

 
          let rowData = {
            values: [],
            variable: metric.name,
            grupo: metric.grupo,
            metric: metric
          }

          for (let dia = 0; dia < 30; dia++)
          {
            rowData.values['' + dia] = metric.value[dia]
          }

          rowsData.push(rowData)

        

      })


      console.log({ rowsData })

      return rowsData


    },

    simulate()
    {

      this.$emit('change')
    },



    searchChange(value)
    {
      this.$emit('search-change', value);
    },


    onDragEnd(metric)
    {


      console.log(metric + " drag_end")
    },

    onChange(metric, value)
    {

      /*
      if (this.timeout)
      {
        clearTimeout(this.timeout);
      }
 
      this.timeout = setTimeout(() =>
      {
        this.$emit('change', metric, value);
      }, 3000); // delay
*/



    }


  },

  props: {
    metrics: {
      type: Array,
      required: true,
      default: () => []
    },

    cardTitle: {
      type: String,
      default: () => "Variables de Modelamiento"
    }
  },

};
    </script>
    
    <style lang="scss"></style>
  
  
  <style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
  
  
    