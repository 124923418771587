<template>
  <div style="padding: 25px 25px;">
    <b-row>
      <b-col>
        <vue-slider
          style=""
          :id="'slider-input-' + metric.name"
          v-model="value"
          direction="ltr"
          :tooltip="'active'"
          :min="metric.min"
          :marks="metric.marks"
          :max="metric.max"
          :data="metric.data || undefined"
          :interval="metric.interval"
          :width="250"
          class="mb-2 vue-slider-secondary"
        />
      </b-col>
      <b-col>
        <b-form-input
          :id="'input-' + metric.name"
          v-model="value"
          placeholder=""
          :readonly="metric.disabledInput"
        ></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>
    
    <script>
import { BTooltip, BButtonGroup, BButton, VBTooltip, BDropdown, BDropdownItem, BFormInput } from "bootstrap-vue";

import VueSlider from 'vue-slider-component'


export default {
  components: {
    BTooltip,
    BButtonGroup,
    BButton,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    VueSlider,
    BFormInput
  },

  directives: {
    "b-tooltip": VBTooltip
  },

  data: function ()
  {
    return {
      value: '',
      metric: null
    };
  },

  created()
  {
    this.setInitialState(this.params)

  },

  methods: {

    setInitialState(params)
    {
      console.log("customActionsCellRender", this.params)
      this.value = params.value;
      this.metric = this.params.data.metric
    },

    getValue()
    {
      return this.value;
    },

  }
};
    </script>
    
    <style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
    